.premium-order-star {
  color: #daa520;
  font-size: 20px;
}

.create-order-modal {
  .search-table-row {
    cursor: pointer;
  }
}
