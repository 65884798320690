.footer-btns {
  margin-top: 1rem;
  .btn-primary-inverted {
    background-color: white;
    color: #006782;
    border: 1px solid #006782;
    &:hover {
      background-color: #006782;
      color: white;
    }
  }
}
