.e-row.saved-occassion {
  td {
    background-color: $light-success !important;
  }
}

.occassion-done-btn {
  position: absolute;
  left: 22px;
  top: 45px;
  z-index: 10;
  padding: 5px 8px;
  margin: 6px;
}

.occassion-template {
  margin: 8px 0;
}

.occassion-img-template {
  width: 30px;
}
.occasion-badge {
  padding: 3px;
  cursor: pointer;
  span {
    font-size: 90%;
  }
}

.occasions-toolbar {
  margin-bottom: 0;
  align-self: center;
  width: 33%;
}
