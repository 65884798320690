.area-select {
  .control-pane {
    border: 1px solid $select-border;
  }
}

.area-link {
  letter-spacing: 0.5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.area-status {
  text-align: center;
}
