.template-download-link {
  display: inline-block;
  color: $primary-color;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
.template-download-link:hover {
  text-decoration: underline;
}
