.coupoun-section {
  .orders-list-btn {
    margin-top: 15px;
  }
  .generate-code {
    color: $primary-color;
    cursor: pointer;
  }

  .limited-cat {
    margin-bottom: 10px;
    .add-more {
      font-weight: bold;
    }
  }
}

.e-disabled {
  pointer-events: none;
}

.e-disabled {
  pointer-events: none;
}
