.banner-info-card {
  .filter-select .control-pane {
    border-color: #ced4da;
  }
  hr {
    width: 98%;
  }
  .file-input {
    label {
      width: 50%;
      margin: 0.5rem auto;
    }
  }
  .mobile-title {
    label {
      font-weight: bold;
      font-size: 18px;
      color: #53a6d6;
      text-decoration: underline;
    }
  }
  .date-filter {
    background-color: white;
  }
  .date-filter-reset {
    position: absolute;
    width: 20px;
    cursor: pointer;
    right: 22px;
  }
}

.add-ops-btn-container,
.excluded-ops-table {
  width: 100%;
  padding: 0 35px;
}

.ops-cancel {
  margin-right: 15px;
}

.full-w-auto-h {
  width: 100%;
  max-width: 100%;
  height: auto;
}
