/**=====================
     42. Timeline CSS Start
==========================**/
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      &.open {
        ~ .page-body {
          .timeline-small {
            .media {
              .timeline-round {
                &.timeline-line-1 {
                  &:after {
                    bottom: -66px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.timeline-small {
  .media {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .timeline-round {
      position: relative;
      width: 53px;
      height: 53px;
      border-radius: 100%;
      top: -18px;
      svg {
        position: absolute;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 18px;
        right: 0;
        margin: 0 auto;
        path,
        line,
        circle {
          color: $white;
        }
      }
      &.timeline-line-1 {
        &:after {
          position: absolute;
          content: '';
          background-color: #e9e9e9;
          width: 2px;
          height: 62px;
          bottom: -77px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      &.small-line {
        &:after {
          position: absolute;
          content: '';
          background-color: #e9e9e9;
          width: 2px;
          height: 19px;
          bottom: -34px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      &.medium-line {
        &:after {
          position: absolute;
          content: '';
          background-color: #e9e9e9;
          width: 2px;
          height: 53px;
          bottom: -68px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
    .media-body {
      h6 {
        font-weight: 600;
        margin-bottom: 30px;
        span {
          font-weight: normal;
          color: $gray-60;
        }
      }
      p {
        width: 80%;
      }
    }
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
/**=====================
     42. Timeline  CSS Ends
==========================**/
