.production-status-btn {
  padding: 5px;
  border-radius: 4px;
}

.custom-toolbar-button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 1.75rem;
  letter-spacing: 0.7px;
  border-radius: 3px;
  border: 1px solid transparent;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white;
  &.getproducts-button {
    background-color: #50a3e6;
    &:hover {
      background-color: darken(#50a3e6, 10%);
    }
  }
  &.release-button {
    background-color: #89ca00;
    &:hover {
      background-color: darken(#89ca00, 10%);
    }
  }
  &.stop-button {
    background-color: #f4aa24;
    &:hover {
      background-color: darken(#f4aa24, 10%);
    }
  }
  &.restart-button {
    background-color: #faa989;
    &:hover {
      background-color: darken(#faa989, 10%);
    }
  }
  &.delete-button {
    background-color: #fe3d5e;
    &:hover {
      background-color: darken(#fe3d5e, 10%);
    }
  }

}

.production-link {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.e-toolbar-item.e-template.e-overlay[aria-disabled="false"] {
  opacity: 1;
  .custom-toolbar-button {
    background-color: #D8D8D8;
  }
}

.production-listing-table{
  margin-bottom: 30px;
  .e-toolbar-left{
    padding-left: 10px;
  }
}
