.inventory-page {
  .inventory-item-qty {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
}

.inventory-supp-trash {
  color: $danger;
  font-size: 20px;
  cursor: pointer;
}

.supp-company-name {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
}
