.img-contain {
  object-fit: contain;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
}

.img-contain-small {
  object-fit: contain;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
}

//colors-classes
.primary {
  color: $primary;
}

.red {
  color: $red;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;

  .title {
    font-size: 15px;
    font-weight: bold;
    margin: 15px 0;

    .icon {
      color: $warning;
      font-size: 20px;
    }
  }
}

.ck-content {
  height: 150px !important;
}

.e-grid.e-default .e-gridheader th.e-firstcell,
.e-grid.e-default .e-gridheader th.e-lastcell {
  border-left-width: 0px;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-l-6 {
  padding-left: 6px !important;
}
.p-6 {
  padding: 6px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-18 {
  padding: 18px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-10 {
  margin: 10px !important;
}

.m-0-auto {
  margin: 0 auto;
}

.m-r-4 {
  margin-right: 4px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-3 {
  margin-bottom: 3px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.no-border-r {
  border-right: none !important;
}

.top-white-border {
  border-top: 1px solid white;
}

.align-center {
  text-align: center !important;
}

.align-left {
  text-align: left !important;
}

.between-flex {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.around-flex {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
}

.baseline-flex {
  display: flex !important;
  align-items: baseline !important;
}

.normal-flex {
  display: flex !important;
  align-items: center !important;
}

.end-flex {
  display: flex !important;
  align-items: flex-end !important;
}

.col-flex {
  display: flex !important;
  flex-direction: column !important;
}

.bold-title {
  font-weight: bold !important;
}

.normal-text {
  font-weight: normal !important;
}

.w-30p {
  width: 30px !important;
}

.w-40p {
  width: 40px !important;
}

.w-60p {
  width: 60px !important;
}
.w-80p {
  width: 80px !important;
}

.w-70p {
  width: 70px !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-55 {
  width: 55% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.h-30p {
  height: 30px !important;
}
.h-60p {
  height: 60px !important;
}

.h-600p {
  height: 600px !important;
}

.h-700p {
  height: 700px !important;
}

.h-100 {
  height: 100% !important;
}

.h-90 {
  height: 90% !important;
}

.h-80 {
  height: 80% !important;
}

.h-70 {
  height: 70% !important;
}

.h-60 {
  height: 60% !important;
}

.h-55 {
  height: 55% !important;
}

.h-50 {
  height: 50% !important;
}

.h-40 {
  height: 40% !important;
}

.h-35 {
  height: 35% !important;
}

.h-30 {
  height: 30% !important;
}

.h-20 {
  height: 20% !important;
}

.h-10 {
  height: 10% !important;
}

.h-auto {
  height: auto !important;
}

.pointer {
  cursor: pointer !important;
}

.primary-color {
  color: #0c697e !important;
}

.black-color {
  color: black !important;
}

.m-w-1500 {
  max-width: 1500px !important;
}

.m-w-1300 {
  max-width: 1300px !important;
}

.m-w-1000 {
  max-width: 1000px !important;
}

.m-w-900 {
  max-width: 900px !important;
}

.m-w-800 {
  max-width: 800px !important;
}

.m-w-700 {
  max-width: 700px !important;
}

.m-w-600 {
  max-width: 600px !important;
}

.m-w-500 {
  max-width: 500px !important;
}

.m-h-350 {
  max-height: 350px !important;
}

.sz-20 {
  font-size: 20px !important;
}

.sz-18 {
  font-size: 18px !important;
}

.sz-17 {
  font-size: 17px !important;
}
.sz-16 {
  font-size: 16px !important;
}

.sz-15 {
  font-size: 15px !important;
}

.sz-14 {
  font-size: 14px !important;
}

.sz-10 {
  font-size: 10px !important;
}

.hide-display {
  display: none !important;
}

.block-display {
  display: block !important;
}

.content-display {
  display: contents !important;
}

.radius-5 {
  border-radius: 5px !important;
}

.radius-9 {
  border-radius: 9px !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.wp-normal {
  white-space: normal !important;
}

.ltr {
  direction: ltr !important;
}

.top-30 {
  top: 30px !important;
}

.light-grey-color {
  color: #898989 !important;
}

.light-red-color {
  color: #dd4b39 !important;
}

.red-color {
  color: red !important;
}

.white-color {
  color: white !important;
}

.green-color {
  color: green !important;
}

.orange-color {
  color: #daa520 !important;
}

.light-blue-bg {
  background-color: #d9edf7 !important;
}

.border-collapse-unset {
  border-collapse: unset !important;
}

.white-bg {
  background-color: white !important;
}

.text-underline {
  text-decoration: underline !important;
}

.red-border {
  border-color: red;
}

.requird-asterisks {
  color: $red;
}

.card-body {
  .form-group {
    .form-control {
      height: 40px;
    }
  }
}

.theme-form .form-group input#dateRange {
  border-color: #eff0f1;
  background-color: #fff;
}

.e-grid .e-rowcell .e-checkbox-wrapper,
.e-grid .e-rowcell .e-css.e-checkbox-wrapper {
  width: 20px;
}
