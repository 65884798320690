.slide-pane-style {
  // padding-top: 70px !important;
  // max-width: 1000px;
  min-width: 350px;
  .close-slide-pane {
    position: absolute;
    left: 5px;
    top: 15px;
    color: $gray-60;
    cursor: pointer;
  }
}
.slide-pane__content {
  padding: 45px !important;
}

.slide-pane__overlay {
  z-index: 1000;
}
