.key-value-form {
  .file-input {
    label {
      .file-name {
        margin-bottom: 0;
      }
    }
  }
  .checkbox {
    display: flex;
    align-items: center;
  }
}
