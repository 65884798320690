.tabs {
  display: flex;
  flex-direction: column;
  .labels-wrapper {
    // min-width: 120px;
    display: flex;
    height: fit-content;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 20px;
    border-bottom: 1px solid #eff0f1;
    .tab-label {
      padding: 10px 20px;
      border-bottom: 1px solid $light-semi-gray;
      cursor: pointer;
      margin-bottom: -1px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &.active-tab-label {
        // background-color: #0c697e;
        // color: white;
        color: $primary-color;
        font-weight: bold;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid $light-semi-gray;
        border-bottom-color: white;
        border-top-color: #0c697e;
        border-top-width: 2px;
      }

      .red-dot {
        color: red;
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        font-size: 10px;
      }
    }
  }
  .tabs-content-wrapper {
    // border-left: 2px solid #0c697e;
    padding: 10px 50px;
    .tab-content {
      // min-height: 629px;
      display: none;
      &.active-tab-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
