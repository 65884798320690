.new-driver {
  display: flex !important;
  justify-content: space-between !important;
  .btn-success {
    margin-left: 10px !important;
  }
}

.search-driver-div {
  display: flex !important;
  margin-bottom: 0 !important;
  .theme-form {
    width: 100% !important;
    margin-right: 8px !important;
  }
}

.refresh-driver-date-range {
  position: absolute !important;
  width: 20px !important;
  cursor: pointer !important;
  right: 22px !important;
}

.driver-section {
  p:first-child {
    white-space: nowrap;
  }
  p {
    margin: 0;
  }
  tbody tr td {
    padding-top: 10px;
    white-space: nowrap;
  }
}
