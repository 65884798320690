*,
::after,
::before {
  box-sizing: border-box;
}
.breadcrumbs {
  display: flex;
  // background-color: #e9ecef;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;

  .divider {
    padding: 0 5px;
  }

  .breadcrumb {
    margin-bottom: 1rem;
    list-style: none;
    color: black;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;

    a {
      color: $info-color;

      &.active {
        color: $dark-gray;
        text-decoration: none;
        cursor: initial;
      }
      &:after {
        display: inline-block;
        padding: 0 0.5rem;
        color: $dark-gray;
        content: '/';
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

a {
  color: $info-color;
  &.active {
    color: lighten($info-color, 10%);
    text-decoration: none;
    cursor: initial;
  }
}
