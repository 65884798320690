.tickets-orderId {
  color: $primary-color;
  display: inline;
}

.comment-container {
  margin-top: 40px;
  .comment-wrapper {
    border-radius: 9px;
    border: 1px #eee solid;

    > div:first-child {
      padding: 0;
    }

    .comment-header {
      text-align: end;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: #eee;
      font-weight: bold;
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;

      .comment-by-wrapper {
        display: flex;
        .comment-by {
          margin: 0;
          color: #0c697e;
        }
      }

      .comment-date {
        margin: 0;
        color: #999;
      }
    }

    .comment-content-wrapper {
      display: flex;
      .comment-content {
        font-size: 15px;
        color: black !important;
      }
    }
  }
}

.assign-ticket-search {
  margin-bottom: 15px;
}

.assign-results-list {
  cursor: pointer;
}

.comment-header-wrapper {
  display: flex;
  .comment-header-title {
    margin-right: 10px;
    color: #0c697e;
  }

  .comment-header-orderId {
    text-decoration: underline;
    cursor: pointer;
  }
  .comment-header-tech-type {
    // text-decoration: underline;
    color: red;
    margin-right: 15px;
  }
}

.ticket-assign-to {
  margin-left: 0 !important;
  color: black;
  font-weight: bold;
}

.ticket-topic-name {
  margin-left: 0 !important;
  color: #0c697e;
  font-weight: bold;
}

.close-ticket-btn {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
}

.ticket-description {
  display: flex;
  justify-content: space-between;
}

.ticket-attachment-btn {
  align-self: center;
  font-size: 5px;
  padding: 5px 20px;
  margin-right: 10px;
}

.ticket-attachment-wrapper {
  margin-bottom: 10px;

  .ticket-attachment-images {
    width: 70px;
    margin-right: 7px;
    cursor: pointer;
    display: inline;
  }
}

.ticket-comments-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .ticket-comments-box {
    margin-bottom: 0;
    .ticket-comment-area {
      margin-bottom: 5px;
    }
    .ticket-comment-post-btn {
      margin-top: 10px;
    }
  }
}

.closed-ticket-title {
  margin-top: 15px;
}

.closed-ticket-by {
  margin: 0 !important;
  color: #999;
}

.comments-counter {
  position: absolute;
  right: 15px;
  bottom: 10px;

  .comment-icon {
    color: #898989;
  }
}

.ticket-filter-wrapper {
  .ticket-filter-header {
    padding: 18px;
  }

  .ticket-search-label {
    font-weight: bold;
  }
}

.topic-show-for-customer {
  font-weight: bold;
}

.topic-header {
  display: flex;
  justify-content: space-between;
}

.open-ticket-status-icon {
  font-size: 21px;
  color: red;
}

.assigned-ticket-status-icon {
  font-size: 21px;
  color: #0c697e;
}

.closed-ticket-status-icon {
  font-size: 21px;
  color: #22af47;
}

.closed-ticket-by {
  p {
    font-size: 12px;
    margin: 0px;
  }
}

.ticket-topic-checkbox {
  cursor: default !important;
  &::after {
    cursor: default !important;
    background-color: #e7e7e7;
  }
}

.ticket-user-name {
  text-decoration: underline !important;
  cursor: pointer !important;
  margin-left: 0 !important;
}
