.order-notification-wrapper {
  .card-header-border {
    display: flex;
    align-items: center;
    height: 40px;
    h5 {
      color: #0c697e;
      font-size: 15px;
      i {
        font-size: 18px;
      }
    }
  }
  .recent-notification {
    max-height: 260px;
    overflow-y: auto;
    .media {
      h6 {
        font-size: 13px;
      }
      .media-body {
        span {
          font-weight: bold;
        }
      }
    }
  }
}
