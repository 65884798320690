.ziwo-card-wrapper {
  margin-bottom: 40px;
  .card-header-border {
    .fa-headphones {
      font-size: 22px;
      margin-right: 5px;
      align-self: center;
      margin-left: 5px;
      color: #22af47;
    }
  }
  .table-responsive {
    .up-right-arrow {
      color: #f63838;
    }
    .down-left-arrow {
      color: #38ade4;
    }
    .text-align-center {
      text-align: center !important;
    }
    .text-align-right {
      text-align: right !important;
    }
    .ziwo-status {
      font-size: 20px;
      &.fa-check-circle {
        color: green;
      }
      &.fa-ban {
        color: red;
      }
    }
  }
  .no-result-title {
    text-align: center;
    margin-top: 25px;
  }
}
