.temp-password-wrapper {
  position: relative;
  .temp-password-icon {
    position: absolute;
    right: 8px;
    top: 12px;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
  }
}
