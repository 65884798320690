.menu-item-link {
  cursor: pointer !important;
}

.child-menu-item-link {
  padding: 0 !important;
}

.child-menu-flex {
  display: flex !important;
}

.child-font-sz {
  font-size: 7px !important;
}

.child-of-child {
  margin-left: 12px !important;
  font-size: 13px !important;
}

.compactLogo {
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 5px;
  .switch-to {
    button.dropdown-toggle {
      padding: 0;
      background: transparent;
      &::after {
        border: 0;
      }
      .switch-icon {
        padding: 5px;
        display: inline-flex;
        &.switch-closed {
          svg {
            fill: $white;
          }
        }
        &.switch-opened {
          background-color: rgba($primary-color, 0.09%);
          svg {
            fill: $primary-color;
          }
        }
        /*&:hover{
          background-color: rgba($primary-color, 0.15%);
          svg{
            fill: $primary-color!important;
          }
        }*/
      }
    }
    .dropdown-menu {
      padding: 16px;
      min-width: 400px;
      .switchto-title-div {
        display: flex;
        flex-wrap: nowrap;
        -webkit-box-align: baseline;
        align-items: baseline;
        -webkit-box-pack: end;
        justify-content: flex-end;
        margin-left: 8px;
        margin-right: 8px;
        position: relative;
        margin-bottom: 16px;
        h1 {
          font-size: 15px;
          font-style: inherit;
          line-height: 1.25;
          color: $black;
          font-weight: 600;
          letter-spacing: 0.4px;
          margin-top: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0px;
          flex: 1 1 0%;
          // border-bottom: 1px solid black;
        }
      }
      .dropdown-item {
        background: transparent;
        color: $black;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        width: 100%;
        padding: 8px !important;
        &:hover {
          background-color: rgba($primary-color, 0.15%) !important;
          border-radius: 5px;
        }
        .app-icon-div {
          display: flex;
          width: 25px;
          margin-right: 15px;
          box-shadow: $primary-color 0px 1px 1px, $primary-color 0px 0px 1px 1px;
          border-radius: 8px;
          background-color: $primary-color;
          padding: 6px;
          width: 32px;
          height: 32px;
          svg {
            fill: $white;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .flw-logo-div {
    border-left: 1px solid $light-gray;
    margin-left: 5px;
    .flw-logo {
      width: 50%;
    }
  }
}

.flw-logo {
  width: 55%;
}
