.categoryid-link {
  letter-spacing: 0.5px;
  color: $primary-color;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cat-icon-cell {
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: $primary-color;
  }
}

.category-status {
  text-align: center;
}

.sub-categoryid-link {
  letter-spacing: 0.5px;
  text-align: center;
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-img {
  width: 100%;
  height: 150px;
  max-width: 100%;
  margin-bottom: 1rem;
  max-height: 150px;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0 8px 16px 8px;
  &.file-icon-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    margin: 0px auto 1rem;
  }
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  position: relative;
  border-radius: 0.25rem;
  background: $primary-color;
  background-color: $btn-select-bg;
  border: 1px solid $black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.file-name {
  // color: $white;
  color: $black;
  font-size: 14px;
  font-weight: bold;
  padding: 0.375rem 1.75rem;
  letter-spacing: 0.7px;
}

input.file:hover + label,
input.file:focus + label {
  // transform: scale(1.02);
  background-color: $btn-select-hover-bg;
}

.filter-btn-div {
  align-items: center;
  display: flex;
}

.category-select {
  .control-pane {
    border: 1px solid $select-border;
  }
}

.e-list-item {
  display: flex;
  align-items: center;
}
.e-list-item:first-child > span {
  text-indent: 2px;
}

.e-dropdownbase .e-list-item {
  padding-right: 0px !important;
}

.selectedColorValueTemplate {
  display: flex;
  .selectedColorDisplay {
    display: inline-block;
    height: 20px;
    width: 30px;
    margin-right: 8px;
  }
}

.colorTemplateDisplay {
  display: inline-block;
  height: 20px;
  width: 30px;
  margin-left: 10px;
}

.colorName {
  text-indent: 8px;
}

.e-list-item {
  &:first-child {
    .colorName {
      text-indent: 2px;
    }
  }
  .bg-opt-div {
    display: contents;
  }
}

.mandatory-attr {
  width: 60%;
  margin-left: 18px;
  .mandatory-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10%;
    margin-bottom: 5px;
    &:first-child {
      margin-bottom: 15px;
      font-weight: 600;
    }

    .attr-item {
      padding: 2px 10px 2px 10px;
      background: $secondary-color;
      border-radius: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: medium;
      font-weight: 600;
      width: 45%;
      .icon-close {
        font-size: small;
        margin-left: 10px;
        font-weight: bolder;
        cursor: pointer;
      }
    }
    .attr-name {
      width: 40%;
    }

    input {
      width: 17px;
      height: 17px;
    }
  }
}

.mc-checkbox-wrapper {
  display: flex;
  align-items: end;
  margin-bottom: 1em;
  .mc-isActive {
    margin-right: 20%;
  }

  input {
    width: 17px;
    height: 17px;
  }

  label {
    margin-bottom: 0;
  }
}

.mc-attributes {
  padding: 0.8em;
  margin-right: 15px;
  margin-left: 15px;
  height: 200px;
  overflow-y: auto;
}

.card {
  .mc-card-body {
    padding: 0;
  }
}

#attrCheckbox_popup {
  padding: 10px;
}
