.css-2b097c-container {
  width: 18%;
  position: absolute;
  right: 20%;
  outline: none !important;
  box-shadow: none !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.css-yk16xz-control {
  border: none !important;
  background-color: $light-color !important;
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-1pahdxg-control {
  border-color: $light-color !important;
  box-shadow: none !important;
  background-color: $light-color !important;
}

.page-main-header .main-header-right .nav-right ul li svg {
  margin-top: 0px !important;
  color: $dark-gray !important;
}

.css-26l3qy-menu {
  z-index: 101 !important;
}

//search loader
#loading {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 103px;
  text-align: center;
  color: $white;
  position: absolute;
  top: 64%;
  left: 50%;
  margin: -60px 0 0 -60px;
  font-size: 50px;
  transform-origin: center;
  border: none;
  box-shadow: 0px 0px 40px 35px rgba(82, 82, 157, 0.4);
  animation: blink 3s infinite;
  /*     background-color: #629aa9; */
}

#loading i:before {
  position: absolute;
  top: 25%;
  left: 25%;
  animation: fly 3.5s linear infinite;
  z-index: 99;
  color: $red;
}

.blockUI {
  border: none !important;
}

@keyframes fly {
  0% {
    transform: translate(-80%, 20%) scale(0.5);
  }
  5% {
    transform: translate(-50%, 20%) scale(0.7);
  }
  10% {
    transform: translate(-10%, 0) scale(0.9);
  }
  15% {
    transform: translate(10%, -20%) scale(1);
  }
  20% {
    transform: translate(40%, -40%) scale(1.2);
  }
  30% {
    transform: translate(60%, -50%) scale(1.2);
  }
  40% {
    transform: translate(40%, -70%) scale(1.3);
  }
  50% {
    transform: translate(-30%, -50%) scale(1.2);
  }
  55% {
    transform: translate(-20%, -20%) scale(1.1);
  }
  60% {
    transform: translate(0, 0) scale(1);
  }
  65% {
    transform: translate(50%, 20%) scale(0.9);
  }
  75% {
    transform: translate(80%, 10%) scale(1.1);
  }
  80% {
    transform: translate(60%, 30%) scale(0.9);
  }
  85% {
    transform: translate(30%, 40%) scale(0.9);
  }
  90% {
    transform: translate(0, 40%) scale(0.7);
  }
  100% {
    transform: translate(-50%, 20%) scale(0.5);
  }
}
@keyframes blink {
  0% {
    background: $light-blue;
  }
  50% {
    background: $purple;
  }
  100% {
    background: $light-blue;
  }
}

.header-ops-img {
  width: 30px;
  border-radius: 5px;
}

.search-table-row {
  &:hover {
    background-color: $light-semi-gray;
  }
  &.search-table-row.table-row-selected {
    background-color: #e1e1e1;
  }
}

@media (max-width: 788px) {
  .page-main-header .main-header-right .nav-right > ul > li {
    padding: 0 12px !important;
  }
  .header-ops-img {
    // width: 30%;
    height: 31%;
  }

  .maximize-icon,
  .language-icon,
  .notification-icon {
    display: none !important;
  }

  .css-1uccc91-singleValue {
    font-size: 13px !important;
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul {
    flex-wrap: wrap !important;
    li:first-child {
      flex-basis: 100% !important;
      position: absolute !important;
      top: 58px !important;
      width: 460px !important;
      margin-left: -12px !important;
    }
    .form-control-plaintext {
      width: 100% !important;
      border-radius: 0 !important;
    }
    li:nth-child(5) {
      flex-basis: 20% !important;
    }
  }
  .Typeahead-menu {
    width: 84% !important;
    top: 60px !important;
    left: 4px !important;
  }
  .css-yk16xz-control {
    width: 114px;
    right: 5px;
    height: 100%;
  }
  .css-1uccc91-singleValue {
    left: 0 !important;
  }
}

.form-control-plaintext {
  visibility: visible !important;
}

@media only screen and (max-width: 575px) {
  .page-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul
    .search-form
    .form-control-plaintext {
    visibility: visible !important;
    transform: none !important;
    opacity: 1 !important;
    position: initial !important;
  }
}

.page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:first-child
  .search-form
  .form-group:before {
  background-color: $light-semi-gray !important;
}

.ops-modal-title {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $light-semi-gray;
  line-height: 0.1em;
  margin: 10px 0 20px;
  span {
    background: $white;
    padding: 0 10px;
    font-weight: bold;
    font-size: 15px;
    color: #444;
  }
}

.page-main-header .main-header-right .nav-right > ul > li:nth-child(5) {
  border-right: 0px !important;
}
