.prev-address-wrapper {
  height: 600px;
  .table-bordernone {
    thead {
      padding-top: 15px;
      th {
        font-size: 14px;
        white-space: nowrap;
        color: #0c697e;
        padding-top: 20px;
      }
    }
    tbody {
      .search-table-row {
        cursor: pointer;
        .order-id {
          padding: 30px;
          color: #0c697e;
          text-decoration: underline;
        }
        td {
          padding-bottom: 20px;
        }
      }
    }
  }
}
.prev-modal-footer-wrapper {
  margin: 0;
  .actions-wrapper {
    text-align: center;
  }
}
