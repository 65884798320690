.drivers-wrapper {
  padding-top: 20px;
  .card-header-border {
    height: 40px;
    display: flex;
    align-items: center;
    h5 {
      font-size: 15px;
    }
  }
  .add-driver-btn-wrapper {
    padding-top: 20px;
  }
}
