/**=====================
     48. Form Wizard CSS Start
==========================**/
ol {
  display: flex;
  &.progtrckr {
    li {
      width: 100%;
      em {
        display: none;
      }
      &.progtrckr-todo,
      &.progtrckr-doing,
      &.progtrckr-done {
        &:before {
          font-size: 20px !important;
          width: 30px !important;
          height: 30px !important;
          line-height: 30px !important;
        }
      }
    }
  }
}
.multi-step {
  position: relative;
  .footer-buttons {
    .btn-lg {
      font-size: 14px;
    }
  }
  .btnsubmit {
    position: absolute;
    bottom: -34px;
    right: 15px;
  }
}
/**=====================
    48. Form Wizard CSS Ends
==========================**/
