.table-edit-action-wrapper {
  .table-edit-action-icon {
    width: 38px;
    cursor: pointer;
    margin-right: 6px;
  }

  .edit-action-icon {
    font-size: 30px;
    color: $primary-color;
    cursor: pointer;
  }
}
