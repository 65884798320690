.support-ticket-modal-wrapper {
  max-width: 600px;
  .search-ticket {
    margin-bottom: 15px;
  }
  .ticket-search-result-table {
    max-height: 220px;
    &.show-ticket-table {
      height: auto;
    }
  }
  .search-table-row {
    cursor: pointer;
  }
}
