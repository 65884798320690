.edit-address-modal {
  max-width: 650px;
  &.prev-address-modal {
    max-width: 850px;
  }
  .btn-view-more {
    background-color: white;
    padding: 0 15px 0 0;
    color: #0c697e;
    text-decoration: underline;
    font-size: smaller;
  }
  .map-btn {
    position: absolute;
    width: 25%;
    z-index: 2;
    top: 37.5%;
    left: 37.5%;
    background-color: #0c697e;
    color: white;
    border-style: none;
    padding: 5px;
  }
  .col-form-label {
    padding: 0;
    font-size: smaller;
  }
  .phone-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .map-img {
    max-width: 100%;
    height: 100px;
    object-fit: cover;
  }
  .address-card {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 15px;
    padding: 15px;
  }
  .modal-body-wrapper {
    align-items: baseline;
  }
  .action-btns-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    &.column-direction {
      flex-direction: column;
    }
    .margin-bottom-btn {
      margin-bottom: 10px;
    }
  }
  .house-photo-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    &.column-direction {
      flex-direction: column;
    }
    img {
      width: 60px;
    }
  }
  .modal-footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
