.product-log-icon {
  font-size: 20px;
  color: mediumvioletred;
}

.search-btn-container {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.log-changes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  table {
    border: 1px solid #ebebeb;
    width: 100%;
  }
  th,
  td {
    border: 1px solid #ebebeb;
    padding: 10px;
    width: 90px;
  }
  td {
    font-weight: normal;
  }
}

.show-more-link {
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}

.product-log-notification {
  max-height: unset !important;
  overflow-y: unset !important;
}

.space-btwn-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.product-actions-dropdown {
  .product-actions-dropdown-menu {
    position: relative;
    .product-dropdown-item {
      display: flex;
      justify-content: space-between;
      background-color: white !important;
      color: black !important;
      border-bottom: 1px solid #dedede;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #2098d3 !important;
        color: white !important;
        &:disabled {
          cursor: not-allowed;
          background-color: white !important;
          color: black !important;
        }
      }
    }
    .product-status-items {
      position: absolute;
      width: 100%;
      left: 100%;
      box-shadow: #0000002d 0px 6px 12px 0px;
      .status-item {
        background-color: white !important;
        color: black !important;
        border-bottom: 1px solid #dedede;
        &:hover {
          background-color: #2098d3 !important;
          color: white !important;
        }
      }
    }
  }
}

.print-actions-dropdown {
  margin-bottom: 10px;
  margin-right: 18px;
  .btn {
    margin-bottom: 0px;
    margin-right: 0px;
    border-radius: 4px!important;
  }
  .print-actions-dropdown-menu {
    position: relative;
    .print-dropdown-item {
      display: flex;
      justify-content: space-between;
      background-color: white !important;
      color: black !important;
      border-bottom: 1px solid #dedede;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #22af47 !important;
        color: white !important;
        &:disabled {
          cursor: not-allowed;
          background-color: white !important;
          color: black !important;
        }
      }
    }
  }
}