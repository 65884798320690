.customerDiv {
  .quick-orders-user {
    color: $primary-color;
    cursor: pointer;
  }
}

.show-comments-link {
  padding: 15px;
  color: red !important;
  text-decoration: underline !important;
  cursor: pointer;
  font-weight: bold;
  display: flex;
}

.comments-table {
  padding: 15px;
}
