.ticket-list-box-wrapper {
  .card-header-border {
    align-items: center;
  }
  .recent-notification {
    .fa-ticket {
      font-size: 22px;
      margin-right: 5px;
      align-self: center;
    }
    .ticket-title {
      font-size: 18px;
    }
  }
  .ticket-list-wrapper {
    max-height: 400px;
    overflow-y: auto;
    .ticket-list-card {
      box-shadow: none;
      margin-bottom: 0;
      .recent-notification {
        padding: 15px;
        .ecommerce-widget {
          margin-bottom: 15px;
          .support-ticket-font {
            cursor: pointer;
            .ticket-item-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              .ticket-item-title {
                color: #0c697e;
              }
            }
          }
          .ticket-item-status-wrapper {
            border-top: 1px solid #f1f3ff;
            padding: 5px;
            background-color: #eee;
            font-weight: bold;
            display: flex;
            justify-content: space-around;
            .ticket-item-openedby {
              color: #777777;
            }
            .ticket-gray-status {
              color: gray;
            }
            .ticket-red-status {
              color: red;
            }
            .ticket-green-status {
              color: green;
            }
            .ticket-item-open-date {
              color: #777777;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
