.table-design-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-design-types-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .flower-type-container {
    margin-right: 15px !important;
    margin-bottom: 15px !important;
    .flower-type-img {
      width: 100px;
      height: 100px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    p {
      // padding: 2px 5px;
      // border: 0.5px solid $primary-color;
      // background-color: rgba($primary-color, 0.1);
      // border-radius: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // width: fit-content;
      // margin-right: 5px!important;
      // margin-bottom: 5px!important;
      // color: $primary-color;
      font-size: 11px;
    }
  }
}

.design-images-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  .design-media {
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .design-img {
    object-fit: cover;
    cursor: pointer;
    width: 250px;
    height: 250px;
  }
  .design-button {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.55); /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7); /* Green */
    }
  }
}

.status-btns-container {
  display: flex;
  flex-direction: row;
  .generic-status {
    cursor: pointer !important;
    margin-right: 15px;
  }
}

.design-user-container {
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 10px;
  }
}
.product-list {
  max-height: 325px;
  overflow-y: auto;
  .product-card {
    border: 1px solid transparent;
    .product-img {
      max-height: 85px;
      width: 100%;
    }
    &.selected {
      border: 1px solid #0c697e;
    }
  }
}

.media-transaction {
  display: flex;
  align-items: center;
  .transaction-id {
    margin: 0 10px;
  }
}

.transaction-p {
  margin: 0;
  margin-left: 24.5px;
}

.change-payment-status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.change-status-modal-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  button {
    width: 45%;
  }
}

.bank-details-container {
  margin-left: 24.5px;
  margin-top: 20px;
  p {
    margin: 0;
    color: black !important;
  }
  span {
    font-weight: bold;
    margin-left: 0 !important;
  }
}

.florist-transaction-comments {
  .media-body {
    display: flex;
    &.dated-comments {
      text-align: end;
      justify-content: space-between;
      padding: 10px;
      background-color: #eee;
      font-weight: bold;
      .comment-by {
        display: flex;
        p {
          margin-bottom: 0;
          color: #0c697e;
        }
      }
      .comment-date {
        margin-bottom: 0;
        color: #999;
      }
    }
  }
}

.florist-transaction-comment {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.transaction-assign-modal {
  max-height: 200px;
  &.show-table {
    height: auto;
  }
}
