.action-box-wrapper {
  .action-box-header {
    height: 40px;
    display: flex;
    align-items: center;
    h5 {
      font-size: 15px !important;
      color: #0c697e;
      i {
        font-size: 18px;
      }
    }
  }
  .recent-notification {
    max-height: 460px;
    overflow-y: auto;
    .media {
      h6 {
        font-size: 13px;
      }
      .media-body {
        font-weight: bold;
      }
    }
  }
}
