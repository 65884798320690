.recipient-address-modal-wrapper {
  max-width: 1000px;
  .shopping-table {
    height: 400px;
    .table-bordernone {
      thead {
        padding-top: 15px;
        th {
          font-size: 14px;
          white-space: nowrap;
          color: #0c697e;
          padding-top: 20px;
        }
      }
      tbody {
        .search-table-row {
          cursor: pointer;
          .order-id {
            padding: 30px;
            color: #0c697e;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .recipient-modal-footer {
    text-align: center;
  }
}
