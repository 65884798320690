.add-key-button {
  margin-left: 10px;
}
.key-value-grid {
  .MuiSwitch-colorPrimary.Mui-checked {
    color: #22af47 !important;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #1c7730 !important;
  }
  .fa-edit {
    color: #0c697e;
    cursor: pointer;
  }
  .icon-img-template {
    max-height: 120px;
  }
}
