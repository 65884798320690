.comment-box-wrapper {
  padding: 0;
  .card-header-border {
    height: 40px;
    display: flex;
    align-items: center;
    h5 {
      font-size: 15px;
      color: #0c697e;
      i {
        font-size: 20px;
      }
    }
  }
  .recent-notification {
    .comments-container {
      max-height: 250px;
      overflow-y: auto;
      .media {
        h6 {
          font-size: 13px;
        }
        .media-body {
          > div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-weight: bold;
            }
            a {
              color: #0c697e;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .theme-form.e-commerce-form {
      .form-group {
        label {
          padding-top: 10px;
        }
      }
    }
  }
}
