.ordered-item-img {
  width: 130px;
}

.ordered-items-filter-wrapper {
  .card-header {
    padding: 18px;
  }
  .card-body .row {
    align-items: flex-end;
    .range-time {
      display: flex;
      flex-direction: column;
      label {
        font-weight: bold;
      }
      .range-input-wrapper {
        display: flex;
        align-items: center;
        input {
          background-color: white;
          cursor: pointer;
        }
        .range-icon {
          position: absolute;
          width: 20px;
          cursor: pointer;
          right: 22px;
        }
      }
    }
    .filter-select {
      label {
        font-weight: bold;
      }
    }
  }
}

.ordered-item-img-columns {
  width: 150px;
}

.ordered-items-delivery-title {
  font-weight: bold;
}
