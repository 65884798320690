.cart-details-wrapper {
  margin-bottom: 20px;
  .card-header {
    .cart-details-btn {
      padding: 6px 20px;
      margin-right: 10px;
      margin-bottom: 3px;
    }
  }
  .table-bordernone {
    tbody:first-child {
      height: 180px;
      width: 550px;
    }
    .product-name {
      div:first-child {
        font-weight: bold;
      }
      a {
        text-decoration: underline;
        color: #dd4b39;
        cursor: pointer;
      }
    }
    .sub-product-name {
      a {
        text-decoration: underline;
        color: black;
        cursor: pointer;
      }
    }
    .cart-items-qty {
      color: red;
      font-weight: bold;
      font-size: 16px;
    }
    .cart-item-qty {
      font-weight: normal;
    }
    .basket-item-wrapper {
      margin: 0 auto;
      display: flex;
      img {
        margin-right: 8px;
        width: 60px;
        height: 60px;
      }
      > div:first-child {
        text-align: left;
        p {
          text-decoration: underline;
          color: black;
        }
      }
    }
    .img-cursor {
      cursor: pointer;
    }
    tfoot {
      border-top: 1px solid #e9ecef;
    }
    .align-left {
      text-align: left;
    }
  }
}

.show-fav-flowers {
  text-decoration: underline;
  color: $primary-color;
  cursor: pointer;
}
