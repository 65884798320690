.inventory-wrapper {
  padding-top: 20px;
  .inventory-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px;

    .header {
      padding-top: 0 !important;
    }

    .add-inventory {
      font-size: 27px;
      margin-right: 5px;
      color: $success-color;

      &:hover {
        opacity: 0.9;
      }
    }
  }
  .inventory-qnty {
    color: blue;
    cursor: pointer;
  }
}
