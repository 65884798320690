.action-modal {
  .action-header {
    justify-content: center;
  }
  .action-footer {
    padding: 0;
    flex-wrap: nowrap;
    justify-content: space-around;
    .action-buttons {
      height: 100%;
      width: 100%;
      padding: 0.75rem 1rem;
      background-color: white !important;
      margin: 0;
      color: #007aff;
      border: none;
      border-radius: 0;
      font-size: 1rem;
      &:hover {
        background-color: rgb(226, 226, 226) !important;
        // opacity: 0.5;
        transition: opacity 0.5s;
      }
      &:active {
        color: #007aff;
        background-color: rgb(204, 204, 204) !important;
      }
      &.action-btn {
        font-weight: bold !important;
        border-right: 2px solid #e9ecef !important;
        border-bottom-left-radius: 0.3rem;
      }
      &.cancel-btn {
        border-bottom-right-radius: 0.3rem;
      }
    }
  }
}
