//material table styles

.material-table-wrapper{
  .MuiTableHead-root{
    border: 1px solid #eff0f1;
  }
  
  .MuiTableCell-root{
    padding: 10px;
  }
  
  .MuiTableRow-root:hover {
    background-color: $light-gray !important;
  }
  
  button:has(.excel-export-btn) {
  position: absolute ;
  left: 0px ;
  cursor: pointer;
    &:focus{
        outline: none;
    }
  }

  .MuiButtonBase-root{
    outline: none;
  }

  .MuiTableCell-head{
    text-align: center;
  }

  .MuiTableRow-root p {
    margin: 0;
  }

  .MuiTableRow-root td{
    text-align: center;
  }

  .MuiTableRow-root:nth-child(2n) {
    background-color: $light-gray2;
  }
}

.MuiPopover-paper{
  max-height: 40% !important;
}

.MuiCheckbox-root{
  color: $primary-color !important
}
