.center-modal-footer {
  display: flex;
  justify-content: center;
}

.card-msg-modal {
  max-width: 1100px;
}

.cart-modal {
  max-width: 600px;
}

.create-order-modal {
  max-width: 600px;
  .shopping-table {
    max-height: 220px;
    &.auto-height-table {
      height: auto;
    }
  }
  .delivery-time-select {
    padding-top: 7px;
  }
}

.recepient-order-modal {
  max-width: 1000px;
  .shopping-table {
    height: 600px;
  }
  .modal-footer {
    > div:first-child {
      text-align: center;
    }
  }
}

.order-priority-contianer {
  min-width: 165px;
}

.note-team-img {
  border-radius: 5px;
}

.bulk-create-order-modal {
  max-width: 900px;
}

.bulk-orders-list {
  th:first-child {
    width: 17.206% !important;
  }
  th:nth-child(2) {
    width: 17.783% !important;
  }
  th:nth-child(3) {
    width: 14.319% !important;
  }
  th:nth-child(4) {
    width: 12.356% !important;
  }
  th:nth-child(5) {
    width: 15.473% !important;
  }
  th:nth-child(6) {
    width: 9.353% !important;
  }
  th:nth-child(7) {
    width: 13.51% !important;
  }
  .confirmation-table-scroll {
    width: calc(100% + 15px);
    height: 300px;
    overflow-y: scroll;
    margin-bottom: 30px;
    margin-right: -15px;
    td {
      display: inline-flex;
      padding: 10px 5px;
      align-items: center;
      justify-content: center;
      word-wrap: anywhere;
    }
    td:first-child {
      width: 17.206% !important;
    }
    td:nth-child(2) {
      width: 17.783% !important;
    }
    td:nth-child(3) {
      width: 14.319% !important;
    }
    td:nth-child(4) {
      width: 12.356% !important;
    }
    td:nth-child(5) {
      width: 15.473% !important;
    }
    td:nth-child(6) {
      width: 9.353% !important;
    }
    td:nth-child(7) {
      width: 13.51% !important;
    }
  }
}

.bigger-textarea{
  height: 120px;
}
