/**=====================
    70. Dashboard Server CSS Start
==========================**/
.server-chart {
  overflow: hidden;
  margin-left: -16px;
  #latency-chart {
    margin-bottom: -16px !important;
    width: 415px;
    height: 63px;
  }
  &.layout-server {
    #latency-chart {
      width: 485px;
    }
  }
}
.server-header {
  display: flex;
  align-items: center;
  span {
    &.badge {
      color: $white;
    }
  }
}
.server-widgets {
  .media {
    .top-server {
      margin-left: 10px;
      h6 {
        padding-top: 3px;
        font-weight: 600;
      }
    }
  }
  .bottom-server {
    margin: 30px 0;
    h5 {
      span {
        color: $gray-60;
        svg {
          vertical-align: middle;
        }
      }
      .second-color {
        span {
          color: $theme-body-font-color;
        }
      }
    }
  }
  .last-server {
    h6 {
      color: $gray-60;
    }
  }
}
.server-card-bg {
  background-image: url(../../images/server/hexgon.jpg);
  background-position: right;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.59);
  background-blend-mode: overlay;
  overflow: hidden;
}
.server-chart-container {
  margin: -14px -12px;
  svg {
    g {
      circle {
        fill: none !important;
      }
    }
  }
}
.server-canvas {
  canvas {
    width: 100% !important;
    height: 300px !important;
  }
}
.server-activity {
  .media {
    align-items: center;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .media-body {
      span {
        color: $gray-60;
      }
    }
  }
}
// Server Responsive
@media only screen and (max-width: 1440px) {
  .server-chart-container {
    margin: -10px -9px;
  }
}
@media only screen and (max-width: 1366px) {
  .server-chart {
    #latency-chart {
      width: 542px;
    }
  }
}
@media only screen and (max-width: 1199px) {
  .server-chart {
    #latency-chart {
      height: 62px;
    }
  }
  .server-chart-container {
    margin: -7px -5px;
  }
}
@media only screen and (max-width: 991px) {
  .server-chart-container {
    margin: -9px -5px;
  }
  .server-chart {
    #latency-chart {
      height: 51px;
    }
  }
  .server-widgets {
    .bottom-server {
      margin: 20px 0;
    }
  }
  .server-activity {
    .media {
      margin-bottom: 20px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .server-canvas {
    canvas {
      height: 240px !important;
    }
  }
}
@media only screen and (max-width: 575px) {
  .server-canvas {
    canvas {
      height: 200px !important;
    }
  }
  .server-chart-container {
    margin: -7px -5px;
  }
  .server-widgets {
    .bottom-server {
      margin: 15px 0;
    }
    .media {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .server-activity {
    .media {
      margin-bottom: 15px;
    }
  }
}
@media only screen and (max-width: 360px) {
  .ReactTable {
    .-pagination {
      .-center {
        flex: auto !important;
      }
    }
  }
  .server-header {
    .badge {
      display: none !important;
    }
  }
}
/**=====================
    70. Dashboard Server  CSS End
==========================**/
