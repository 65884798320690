.add-inventory-table-wrapper {
  margin: 10px 0;

  .add-inventory-cart-table {
    img {
      width: 100%;
      max-width: 100px;
    }

    .radio-wrapper {
      cursor: pointer;

      label {
        margin: 0 0 0 10px;
      }
    }
  }
}
