.quick-actions-table {
  .link {
    color: $primary-color;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .generic-status {
    padding: 5px;
    margin-top: 5px;
    font-size: 12px;
  }
  .btn-pointer {
    cursor: pointer !important;
  }

  .icon {
    font-size: 27px;
    color: $primary-color;
    cursor: pointer;
  }

  .actions-note {
    color: $red;
    margin-left: 7px;
  }

  .MuiTableCell-head {
    z-index: 0;
  }

  // .recipient-address {
  // 	max-width: 100px;
  // }
}

.quick-actions-pagination {
  padding: 5px;
  margin-top: 10px;

  ul {
    margin: 5px 0 !important;
  }
}

.vip-order {
  background-color: $black;
  color: $white;
  display: inline-block;
  padding: 2px 6px;
  text-align: center;
  font-size: 14px;
  align-self: center;
  margin-top: 4px;
  border-radius: 3px;
}

.priority-double-up::before,
.priority-1 .e-input-group::before {
  content: '\f102' !important;
  font-family: 'FontAwesome';
  color: $red-priority;
}

.priority-up::before,
.priority-2 .e-input-group::before {
  content: '\f106' !important;
  font-family: 'FontAwesome';
  color: $red-priority;
}

.priority-med::before,
.priority-0 .e-input-group::before {
  content: '\f2d1' !important;
  font-family: 'FontAwesome';
  color: $warning-color;
  margin-top: -5px;
}

.priority-low::before,
.priority-3 .e-input-group::before {
  content: '\f107' !important;
  font-family: 'FontAwesome';
  color: $info-color;
}

// .priority-double-low::before, .priority-1 .e-input-group::before{
// 	content: "\f103"!important;
// 	font-family: 'FontAwesome';
// 	color: #0065ff;
// }

.priority-select {
  // font-family: 'FontAwesome';
  padding: 0;
  width: 170px;
  .snippet {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.priority-0,
.priority-3,
.priority-2,
.priority-1 {
  .e-input-group::before {
    position: unset !important;
    width: fit-content !important;
    font-size: 16px;
    font-weight: bold;
    background: transparent !important;
    height: fit-content !important;
    padding-right: 5px;
  }
}

.priority-double-up,
.priority-up {
  color: $red-priority;
}

.priority-med {
  color: $warning-color;
}

.priority-low,
.priority-double-low {
  color: $info-color;
}

.priority-label {
  font-weight: bold;
}

.priority-icon {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.priority-flag {
  margin-right: 5px;
  color: $danger-color;
  font-size: 16px;
}

.priority-btn.btn-light-warning,
.priority-btn.btn-light-success,
.priority-btn.btn-light-danger {
  min-width: 90px;
  cursor: pointer !important;
}

.vieworder-priority {
  .btn {
    margin: 0;
  }
}

.knet-table {
  td.e-rowcell {
    text-align: center;
  }
}

.today-order-btn {
  padding: 5px;
  margin-top: 5px;
}

.onfleet-template {
  img {
    width: 30px;
  }
}

.chunk-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  > p {
    margin-top: 70px;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    padding: 10px 20px;
  }
  .chunk-loader-container {
    display: block;
    width: 100%;
    text-align: center;
  }
  .note-bg-container {
    background-color: rgba(255, 0, 0, 0.35);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 5px 10px;
    }
    i {
      margin: 5px 0px 5px 10px;
    }
  }
}

.chunk-loader-bg {
  filter: blur(6px);
}

.bulk-datepicker {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: black !important;
}

.order-snackbar-msg {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  i {
    margin-top: 4px;
  }
  p {
    margin: 0;
    padding-left: 5px;
    text-align: left;
  }
  &.error-snackbar-msg {
    background-color: rgba(231, 76, 60, 0.25);
  }
  &.success-snackbar-msg {
    background-color: rgba(7, 188, 12, 0.25);
  }
}

.btn-purple{
  background-color: $purple !important;
}