.add-new-gift-modal {
  max-width: 650px;
  .text-align-center {
    text-align: center !important;
  }
  .gift-form {
    align-items: baseline;
    .gift-photo-wrapper {
      display: flex;
      align-items: center;
      &.column-direction {
        flex-direction: column;
      }
      &.row-direction {
        flex-direction: row;
      }
    }
  }
  .drop-btn-small {
    margin-bottom: 10px;
  }
  .modal-footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
