.status-badge {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 0.25rem 0.5rem;
  &.active {
    color: $success-color;
  }
  &.inactive {
    color: $red;
  }
  .status-badge-icon {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 5px;
    border-radius: 100%;
  }
  .status-badge-icon.active-circle {
    background-color: $success-color;
    border: 2px $success-color solid;
  }
  .status-badge-icon.in-active-circle {
    background-color: $red;
    border: 2px $red solid;
  }
}
