/**=====================
     51. Search CSS Start
==========================**/
.search-page {
  .nav-link {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px !important;
  }
  .borderb-tab-primary {
    margin-bottom: 30px;
  }
  .info-block {
    + .info-block {
      padding-top: 30px;
      border-top: 1px solid $light-color;
      margin-top: 30px;
    }
  }
  .search-links {
    h6 {
      margin-bottom: 0;
    }
  }
  p {
    text-transform: lowercase;
    margin-bottom: 0;
    color: $gray-60;
  }
  ul.search-info {
    li {
      display: inline-block;
      font-size: 12px;
      line-height: 1;
      color: #586082;
      + li {
        border-left: 1px solid lighten($dark-color, 50%);
        padding-left: 8px;
        margin-left: 8px;
      }
      i {
        color: $warning-color;
      }
    }
  }
  #video-links {
    .embed-responsive + .embed-responsive {
      margin-top: 30px;
    }
  }
}

.search-form {
  input {
    background-position: 99%;
    &:focus {
      outline: none;
    }
  }
  .searchHeader-select {
    display: flex !important;
    justify-content: center !important;
    position: relative !important;
    width: 100% !important;
    &.search-mobile {
      padding: 10px !important;
      background-color: white !important;
    }
    .search-header {
      width: 80% !important;
      direction: ltr !important;
      outline: none !important;
      border-radius: 5px !important;
    }
    .search-loader-h {
      height: 200px !important;
    }
  }
}
/**=====================
    51. Search CSS Ends
==========================**/
