.no-toolbar-table-style {
  &.slots-table {
    margin-bottom: 35px;
  }
  .link {
    color: $primary-color;
    cursor: pointer;
  }
  .grid-table-title {
    padding: 15px;
    position: absolute;
    left: 18px;
    z-index: 10;
    &.dashboard-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;

      .header-input {
        margin-bottom: 5px;
      }
    }
  }
  .control-pane {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 5px 24px 0 $box-shadow;
    border: 1px solid $light-semi-gray;
    background-color: $white;
    margin-bottom: 25px;
    .card-header {
      background-color: $white;
      h5 {
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 600;
      }
      h4 {
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    .e-toolbar-items.e-tbar-pos {
      background-color: $white;
    }
  }
  .e-grid {
    border: 0;
    border: 1px solid $light-semi-gray;
    font-family: $font-source-sans-pro;
    .e-summarycell {
      font-size: 14px;
    }
    .e-rowcell {
      padding: 8px 10px;
      font-size: 14px;
      &:first-child,
      .e-grid .e-summarycell:first-child {
        padding-left: 20px;
      }
    }
    .e-toolbar {
      border: 0;
      border: 1px solid $light-semi-gray;
      .e-toolbar-items {
        min-height: 45px;
        .e-toolbar-left .e-toolbar-item {
          padding: 10px 0px;
          height: 100%;
          .e-tbar-btn.e-btn {
            font-size: 14px;
            padding: 0.375rem 1.75rem;
            letter-spacing: 0.7px;
            background-color: $primary-color;
            border-radius: 5px;
            line-height: 1;
            .e-icons,
            .e-tbar-btn-text {
              color: $white;
            }
          }
        }
      }
      &.e-gridhover
        tr[role='row']:not(.e-editedrow):hover
        .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
      .e-grid.e-gridhover
        tr[role='row']:hover
        .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
      .e-grid.e-gridhover
        tr[role='row']:hover
        .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
      .e-grid.e-rtl
        .e-gridhover
        tr[role='row']:hover
        .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
      .e-grid.e-gridhover
        tr[role='row']:hover
        .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
        background-color: $light-semi-gray;
      }
    }

    .e-gridheader {
      border-top: 1px solid $light-color;
      border: 1px solid $light-semi-gray;
      border-bottom: 1px solid $light-semi-gray;
      th.e-headercell {
        padding: 10px !important;
        span.e-headertext {
          font-size: 14px;
          color: black;
          font-weight: 600;
        }
      }
    }
    .e-gridcontent {
      border: 1px solid $light-semi-gray;
      border-bottom: 1px solid $light-semi-gray;
    }

    tr.e-row:nth-child(2n) {
      background-color: $light-gray2;
    }
    .e-headercelldiv {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    .e-rowcell p {
      margin: 0;
    }
    .note-txt {
      color: $red-priority;
      line-height: 1.5;
    }
    .e-pager .e-currentitem,
    .e-pager .e-currentitem:hover {
      background: $primary-color;
    }
    .total-txt {
      margin-left: 5px;
    }
    .e-checkbox-wrapper .e-frame.e-check,
    .e-css.e-checkbox-wrapper .e-frame.e-check {
      background-color: $primary-color;
    }
    .e-checkbox-wrapper:hover .e-frame.e-check,
    .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
      background-color: $dark-primary-color;
    }
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
    .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
    .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
      background: $primary-color;
    }
  }
}

.toolbar-table-style {
  .control-pane {
    border-radius: 8px;
    // overflow: hidden;
    box-shadow: 1px 5px 24px 0 $box-shadow;
    border: 1px solid $light-gray;
    background-color: $white;
    margin-bottom: 25px;
    .card-header {
      background-color: $white;
      h5 {
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
  .e-grid {
    border: 0;
    font-family: $font-source-sans-pro;
    .e-summarycell {
      font-size: 14px;
    }
    .e-rowcell {
      padding: 8px 10px;
      font-size: 14px;
      &:first-child,
      .e-grid .e-summarycell:first-child {
        padding-left: 20px;
      }
    }
    .e-toolbar {
      border: 0;
      border: 1px solid $light-semi-gray;
      height: 75px !important;
      .e-toolbar-items {
        height: 100%;
        .e-toolbar-left .e-toolbar-item {
          padding: 20px 5px;
          .e-tbar-btn.e-btn {
            font-size: 14px;
            padding: 0.3rem 1rem;
            letter-spacing: 0.7px;
            background-color: $main-header-bg-color;
            border-radius: 5px;
            line-height: 1;
            .e-icons,
            .e-tbar-btn-text {
              color: $white;
            }
          }
        }
      }
    }
    &.e-gridhover
      tr[role='row']:not(.e-editedrow):hover
      .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
    .e-grid.e-gridhover
      tr[role='row']:hover
      .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
    .e-grid.e-gridhover
      tr[role='row']:hover
      .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
    .e-grid.e-rtl
      .e-gridhover
      tr[role='row']:hover
      .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
    .e-grid.e-gridhover
      tr[role='row']:hover
      .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
      background-color: $light-gray;
    }
    .e-gridheader {
      border-top: 1px solid $light-color;
      border: 1px solid $light-semi-gray;
      border-bottom: 1px solid $light-semi-gray;

      th.e-headercell {
        padding: 10px !important;
        &:last-child {
          border-right: 0px;
        }
        span.e-headertext {
          font-size: 14px;
          font-weight: 600;
          color: black;
        }
      }
    }

    .e-gridcontent {
      border: 1px solid $light-semi-gray;
    }
    tr.e-row:nth-child(2n) {
      background-color: $light-gray2;
    }
    .e-headercelldiv {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    span.e-headertext {
      text-align: center;
    }
    .e-rowcell p {
      margin: 0;
    }
    .note-txt {
      color: $red-priority;
      line-height: 1.5;
    }
    .e-pager .e-currentitem,
    .e-pager .e-currentitem:hover {
      background: $primary-color;
    }
    .total-txt {
      margin-left: 5px;
      text-align: center;
    }
    .e-checkbox-wrapper .e-frame.e-check,
    .e-css.e-checkbox-wrapper .e-frame.e-check {
      background-color: $primary-color;
    }
    .e-checkbox-wrapper:hover .e-frame.e-check,
    .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
      background-color: $dark-primary-color;
    }
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
    .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
    .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
    .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
      background: $primary-color;
    }
    .e-btn.e-flat.e-primary,
    .e-css.e-btn.e-flat.e-primary {
      color: $primary-color;
    }
    .e-btn.e-flat.e-primary:hover,
    .e-css.e-btn.e-flat.e-primary:hover {
      background-color: #0c697e0a;
      color: $primary-color;
    }
  }
}

.btn-div-container {
  text-align: center;
}

.row-select-checkbox {
  padding: 10px 21px 0 21px !important;
}

.selectAllcheckbox {
  border: 2px solid;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: 'e-icons';
  height: 18px;
  line-height: 10px;
  padding: 2px 0;
  text-align: center;
  vertical-align: middle;
  width: 18px;
  background-color: #fff;
  border-color: #757575;
  color: white;
  &.is-checked {
    background-color: #0c697e;
    border-color: #0c697e;
    &::before {
      content: '\e933';
    }
  }
  &.some-check {
    color: #757575;
    &::before {
      content: '\e934';
    }
  }
}

.e-summaryrow {
  height: 40px;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check,
.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: #0c697e;
}
