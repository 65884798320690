.redirection-link {
  display: inline-block;
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}
.redirection-link:hover {
  text-decoration: underline;
}

.eta-title {
  font-weight: bold;
  font-size: 14px;
}
.eta-wrapper {
  margin: 0;
  padding: 5px;
  background-color: #22af47;
  color: white;
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 20px;
}
.order-info-wrapper {
  margin-bottom: 20px;
  .sender-details-wrapper {
    margin-bottom: 15px;
    h6 {
      display: inline;
    }
    .sender-name {
      color: black;
      span {
        color: #0c697e;
        text-decoration: underline;
      }
    }
    .sender-phone {
      cursor: pointer;
      span {
        color: #0c697e;
      }
    }
    .total-orders-btn {
      padding: 5px;
      margin-top: 5px;
    }
    .share-details-btn {
      padding: 5px;
      margin-top: 5px;
      display: block;
    }
  }
  .receipient-details-wrapper {
    margin-bottom: 15px;
    > div:first-child {
      display: flex;
      align-items: center;
    }
    h6 {
      margin: 0;
    }
    .fa-edit {
      font-size: 25px;
      color: #0c697e;
      cursor: pointer;
      margin-left: 10px;
    }
    .receipient-phone {
      cursor: pointer;
      span {
        color: #0c697e;
      }
    }
    .view-on-map-link {
      color: #0c697e;
      text-decoration: underline;
      font-size: 15px;
    }
    .total-addresses-btn {
      padding: 5px;
      margin-bottom: 7px;
      margin-top: 5px;
    }
  }
  .team-note-wrapper {
    h6 {
      font-weight: bold;
      font-size: 14px;
    }
    .order-team-note {
      margin: 0;
      padding: 5px;
      background-color: #ff5370;
      color: white;
      display: inline-block;
      border-radius: 3px;
      margin-bottom: 20px;
    }
    .order-payment-date {
      margin: 0;
      padding: 5px;
      background-color: #22af47;
      color: white;
      display: inline-block;
      border-radius: 3px;
      margin-bottom: 20px;
    }
    .order-badge {
      margin: 0;
      padding: 5px;
      background-color: #ff5370;
      color: white;
      display: inline-block;
      border-radius: 3px;
      margin-bottom: 10px;
      font-size: 12px;
      margin-top: -10px;
    }
  }
  .order-mbs-title {
    font-weight: bold;
    font-size: 14px;
  }
  .order-mbs-value {
    margin: 0;
    padding: 5px;
    background-color: #22af47;
    color: white;
    display: inline-block;
    border-radius: 3px;
    margin-bottom: 20px;
  }
}

.sales-officers-title {
  font-weight: bold;
}

.recipient-address {
  .recipient-area {
    color: #0c697e;
    margin-bottom: 0;
  }
  p{
    margin-bottom: 0;
  }
}

.sender-recipient-block {
  p{
    margin-bottom: 0;
  }
  .sender-phone {
    margin-bottom: 0;
  }
}
