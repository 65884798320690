.production-import-table-scroll {
  height: 350px;
  overflow-y: scroll;
}

.viewDetailsModal {
  .viewDetailsHeader {
    h5 {
      font-size: 1rem;
    }
    margin-bottom: 0;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
  .viewDetailsBody {
    padding: 0;
    .viewDetailsItems {
      margin: 1rem 0;
      padding: 1rem;
      padding-left: 1.375rem;
      padding-right: 1.375rem;
      font-size: 1rem;
    }
  }
}

.production-edit-product-modal{
  .modal-title {
    width: 100%;
    .production-close {
      cursor: pointer;
    }
  }
  .filter-select{
    .control-pane {
      border: 1px solid #ced4da
    }
  }
  .filter-select-disable{
    .control-pane {
      background-color: #e9ecef;
      opacity: 1;
    }
  }
  .form-control{
    #production-startTime {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &.Mui-disabled {
        background-color: #e9ecef;
        opacity: 1;
      }
    }
    .MuiInput-underline {
      &::before, &::after {
        border-bottom: 0px!important;
      }
    }
  }
}

.warning-message {
  font-size: 12px;
  color: #FF9800;
  background-color: #FFF1DF;
  padding: 2.5px 5px;
  border-radius: 5px;
  border: 1px solid #FF9800;
}