.sender {
  padding: 10px 0;

  .sec-title {
    font-weight: bold;
    color: $primary-color;
  }

  .phone {
    // font-weight: bold;
    letter-spacing: 0.5px;
    cursor: pointer;
  }

  .sender-btn {
    padding: 0.25rem;
  }
}
