.columns-order-id {
  text-align: left;
  a {
    color: #0c697e;
    font-weight: bold;
  }
}
.columns-order-name {
  text-align: left;
  display: flex;
  align-items: center;
}
.columns-order-paydate {
  text-align: left;
}
.columns-order-sender {
  text-align: left;
  padding: 10px 0;
  p span {
    font-weight: bold;
    color: #0c697e;
    &.sender-phone {
      color: #000000de;
      cursor: pointer;
    }
  }
}
.columns-order-recipient {
  text-align: left;
  p span {
    font-weight: bold;
    color: #0c697e;
    &.sender-phone {
      color: #000000de;
      cursor: pointer;
    }
  }
}
.columns-order-address {
  text-align: left;
}

.green-title {
  color: green;
}

.red-title {
  color: red;
}
