.checkout-content > div {
  margin-bottom: 20px;
  span {
    color: $gray-60;
    white-space: nowrap;
  }
  p {
    font-weight: bold;
  }
}
.checkout-card {
  .checkout-card-header {
    height: 30px;
    display: flex;
    align-items: center;

    h5 {
      font-size: 15px;
      color: $success-color;
    }
  }
}
