.customer-call-wrapper {
  padding-top: 20px;
  .card-header-border {
    height: 40px;
    display: flex;
    align-items: center;
    h5 {
      font-size: 15px;
    }
  }
  .recent-notification {
    padding-bottom: 0;
    .nowrap-space {
      white-space: nowrap;
    }
  }
}
