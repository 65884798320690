.map-modal-content-wrapper {
  .loading-elem,
  .container-elem {
    height: 100%;
  }
  .map-elem {
    height: 600px;
  }
  .address-title {
    font-size: 14px;
    p {
      color: red;
      font-size: 15px;
    }
  }
  .map-actions-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.map-link-modal-wrapper {
  max-width: 650px;
  .form-content {
    align-items: baseline;
  }
  .map-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row;
    &.column-direction {
      flex-direction: column;
    }
    .margin-bottom-btn {
      margin-bottom: 10px;
    }
  }
  .cancel-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
