.opsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    border-radius: 5px;
  }
  p {
    margin: 0;
    word-wrap: anywhere;
    text-align: center;
  }
}

.deliveryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dateDiv {
  text-align: center;
}

.dateRow,
.phoneRow {
  margin: 0;
}

.customer-phone {
  color: $primary-color;
  cursor: pointer;
}

.customerDiv {
  p {
    text-align: center;
  }
}

.areaTxt {
  text-align: center;
}

.iconsColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  .iconsContainer {
    display: flex;
    flex-wrap: wrap;
    .iconBG {
      width: 35px;
      height: 30px;
      margin: 5px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.btns-column {
  align-items: center;
}

.btn-light-success,
.btn-light-danger,
.btn-light-warning {
  cursor: default !important;
  padding: 2px 5px !important;
  font-size: 11px !important;
  width: fit-content;
}

.btn-light-success {
  color: $success-color;
  background-color: lighten($success-color, 43%);
  border-color: $success-color;
}

.btn-light-danger {
  color: $danger-color;
  background-color: lighten($danger-color, 28%);
  border-color: $danger-color;
}

.btn-light-warning {
  color: $warning-color;
  background-color: lighten($warning-color, 43%);
  border-color: $warning-color;
}

.orderid-link {
  letter-spacing: 0.5px;
  text-align: center;
}

.customer-service-icon {
  width: 30px !important;
  margin-right: 10px !important;
}
