.mui-table-styling {
  .e-gridheader {
    border-top-width: 0;
  }
  .e-headercell {
    padding: 16px 21px;
    .e-headercelldiv {
      padding: 0 !important;
      margin-left: 0;
      margin-right: 0;
      .e-headertext {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
        letter-spacing: 0.14994px;
        line-height: 24px;
      }
    }
  }
  .e-rowcell {
    padding: 16px 21px;
  }
}

.mui-table-slots {
  .e-grid.e-gridhover
    tr[role='row']:not(.e-editedrow):hover
    .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover
    tr[role='row']:hover
    .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover
    tr[role='row']:hover
    .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-rtl
    .e-gridhover
    tr[role='row']:hover
    .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover
    tr[role='row']:hover
    .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .e-grid td.e-active {
    background-color: #ffffff;
  }
  .tes-mui-icon {
    font-family: Material Icons;
    font-size: 1.5rem;
    flex-shrink: 0;
  }
  .e-flat span::before {
    font-size: 1.1rem;
    flex-shrink: 0;
  }
  .e-unboundcell {
    padding: 0 !important;
    text-align: center !important;
  }
  .e-inline-edit {
    .e-rowcell {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }
  .e-grid .e-icons:not(.e-btn-icon) {
    color: white;
  }
  .e-headercell {
    &:first-child {
      padding: 0;
      padding-left: 0 !important;
    }
    background-color: #edeef0;
    padding: 16px;
    span {
      color: black;
    }
  }
  .e-rowcell {
    padding: 16px;
  }
}

.mui-table-search {
  position: relative;
  .e-grid {
    width: auto;
    border: 0;
    border-radius: 4px;
    .e-toolbar {
      border-top: 0;
      background: transparent;
      position: absolute;
      top: -50px;
      right: 0;
      .e-toolbar-items {
        background: transparent;
        .e-toolbar-right {
          padding-right: 10px;
        }
      }
    }
  }
}
