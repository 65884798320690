.data-table p {
  margin-bottom: 0 !important;
}

.data-table-extensions {
  padding: 0.7rem 0 !important;
}

.data-table .data-table-extensions input {
  width: 500px;
  padding: 7px !important;
  background: $white !important;
  border: 1px solid $semi-dark !important;
  border-bottom: 1px solid $semi-dark !important;
  border-radius: 10px;
  &:focus {
    border-bottom: 2px solid $semi-dark !important;
    border: 2px solid $light-gray;
  }
}

.table-icon-wrapper {
  width: 100%;
  display: flex;
  justify-content: left;
}

.table-icon-style {
  padding: 7px;
  background-color: $blue-color;
  color: white !important;
  border-radius: 5px;
}
.table-icon-delete {
  padding: 7px;
  background-color: $red;
  color: $white !important;
  border-radius: 5px;
}

.table-icon-monitor-style {
  padding: 2px;
  background-color: $btn-search-bg-c;
  color: $white !important;
  border-radius: 5px;
}

@media (max-width: 500px) {
  .data-table .data-table-extensions input {
    width: 250px;
  }
}

.create-order-table thead {
  padding-top: 15px;
  tr th {
    font-size: 14px;
    white-space: nowrap;
    color: $primary-color;
    padding-top: 20px;
  }
}

.customer-info-header {
  padding-top: 30px;
  text-align: center;
  h6 {
    font-weight: bold;
    color: $primary-color;
  }
}
