@font-face {
  font-family: EngFont;
  src: url('../../../../assets/fonts/Gilroy-Regular.ttf');
}

.card-message-wrapper {
  padding-top: 20px;
  .recent-notification {
    padding-bottom: 0;
    .card-col {
      white-space: nowrap;
    }
    .table-bordernone {
      tbody {
        a {
          text-decoration: underline;
          color: #0c697e;
        }
        .printed-icon,
        .not-printed-icon {
          width: 30px;
        }
      }
    }
  }
}

.edit-card-message-container {
  width: 20vw;
  .modal-content {
    border-radius: 10px;
  }
  .edit-card-message-content {
    padding: 0.8rem 1rem;
    border-radius: 10px;

    &.preview-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 16px;
      line-height: 19px;

      .qr-code-wrapper {
        margin-bottom: 1.1rem;
      }
    }

    &.feelings-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      svg {
        font-size: 22px;
        color: $primary;
      }
    }

    p {
      font-size: 16px;
      margin-top: 7.5px;
      margin-bottom: 8px;
      font-family: EngFont, 'Open Sans';
      color: #333333;
      font-weight: 700;
      line-height: 1.2;
      &.message-content {
        min-height: 200px;
      }
    }

    .icon-wrapper {
      padding-inline-end: 1rem;
      border-inline-end: 1px solid $orange;
    }

    .link-wrapper {
      padding-inline-start: 1rem;
      width: 100%;
    }
  }
}

.card-msg-icon {
  font-size: 25px;
  color: #0c697e;
  cursor: pointer;
}

.card-msg-del-icon {
  font-size: 23px;
  color: #ff5370;
  cursor: pointer;
}
