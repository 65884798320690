.ziwo-recorded-calls {
  max-width: 500px;
  .card-body {
    text-align: center;
    audio {
      outline-color: white;
      width: 100%;
    }
  }
}
