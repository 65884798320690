.nav-tabs {
  display: flex;
  // border-color: $primary-color;
  .nav-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
  }

  .nav-item {
    .active {
      background-color: $white;
      border-color: $primary-color $primary-color #fff;
      // border-width: 2px;
    }
  }
}
