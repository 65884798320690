.rf-row {
  display: flex;
  .imp-exp-btns {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.seasonal-filter-header {
  padding: 18px;
}

.seasonal-filter-date {
  display: 'flex';
  align-items: 'center';
  input {
    border-color: #eff0f1 !important;
    background-color: #fff !important;
    font-size: 14px !important;
    color: #898989 !important;
    font-family: work-Sans, sans-serif !important;
    border-radius: 0.25rem !important;
    cursor: pointer !important;
  }
}

.get-products-btn {
  background-color: #53a6d6 !important;
  border-color: #53a6d6 !important;
}
