//react data table
.data-table-extensions > .data-table-extensions-filter {
  display: flex !important;
  align-items: flex-end !important;
  display: none !important;
}

.data-table-extensions-filter .icon {
  display: none !important;
}

.page-link {
  font-size: 17px;
}

.rdt_TableHeader {
  display: none !important;
}

@media (max-width: 500px) {
  .page-link {
    font-size: 10px;
  }
}

.data-table-extensions {
  display: none !important;
}

//data grid
.e-ungroupbutton {
  display: none !important;
}
