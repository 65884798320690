.ccIcon-container {
  display: flex;
}

.visacard-bg {
  width: 30px;
  height: 20px;
  background-color: $info-color;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
}

.mastercard-bg {
  width: 30px;
  height: 20px;
  background-color: #353a48;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
}

.visacard-icon,
.mastercard-icon {
  width: 20px;
  height: auto;
}

.knetcard-bg,
.paypalcard-bg,
.madacard-bg {
  width: 30px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  .knetcard-icon,
  .paypalcard-icon,
  .madacard-icon {
    width: 100%;
    height: auto;
  }
}

.madacard-bg {
  overflow: hidden;
}

.defaut-val {
  color: $dark-gray;
}

.thin-check {
  padding: 0 17px 0 0;
  position: relative;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(1, 1);
    content: '';
    position: absolute;
    left: 0;
    top: 0.225rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.375rem;
    border: 2px solid $dark-gray;
    border-top-style: none;
    border-right-style: none;
  }
}

.thin-close {
  font-style: normal;
  padding-right: 5px;
  font-family: sans-serif;
  color: $dark-gray;
  font-weight: 600;
}

.valid-cc {
  color: $success-color;
  padding: 5px;
  background-color: darken($success-color, 30%);
  border-radius: 3px;
}

.invalid-cc {
  color: $red-priority;
  padding: 5px;
  background-color: darken($red-priority, 30%);
  border-radius: 3px;
}

.extand-cc {
  color: $dark-gray;
}

.cc-details-container {
  cursor: pointer;
  .cc-details {
    display: none;
  }
  &:hover {
    display: flex;
    flex-direction: row;
    border: 1px solid $light-semi-gray;
    width: fit-content;
    align-items: center;
    padding: 0 10px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px $light-semi-gray;
    background-color: $white;
    .cc-details {
      display: block;
      margin: 0;
      padding-right: 5px;
    }
  }
}

.saved-credicard-table {
  .e-detailrowexpand .e-icon-gdownarrow::before,
  .e-detailrowcollapse .e-icon-grightarrow::before {
    content: '';
  }

  .e-detailheadercell,
  tr > td.e-detailrowexpand:first-child,
  tr > td.e-detailrowcollapse:first-child {
    visibility: hidden;
    padding: 0 !important;
    max-width: 0px;
  }
  .cc-details-container.e-dtdiagonaldown,
  .cc-details-container.e-dtdiagonalright {
    text-indent: unset;
    font-size: 14px;
  }
}

.cc-details-close {
  display: none;
}

.e-detailrowexpand {
  .cc-details-close {
    display: block;
    margin: 0;
    padding-right: 5px;
  }
  .cc-details-container {
    display: flex;
    flex-direction: row;
    border: 1px solid $light-semi-gray;
    width: fit-content;
    align-items: center;
    padding: 0 10px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px $light-semi-gray;
    &:hover {
      .cc-details {
        display: none;
      }
    }
  }
}

.e-detailcell {
  border-color: $light-semi-gray !important;
  background-color: $light-semi-gray;
  padding: 8px 21px !important;
}

.cc-details-container.e-dtdiagonaldown {
  background-color: $light-semi-gray;
  margin-bottom: -11px;
  padding-bottom: 11px;
  z-index: 7;
}

.cc-details-title {
  color: $dark-gray;
}

.cardid-val-box {
  display: flex;
  flex-direction: row;
  > input {
    border: 0px;
    color: black;
    padding-right: 10px;
    letter-spacing: 0.5px;
    width: 65%;
    background-color: $light-semi-gray;
  }
  .copy-box {
    display: block;
    border: 1px solid $light-semi-gray;
    height: max-content;
    padding: 2px 7px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px gainsboro;
    color: green;
    cursor: pointer;
    background-color: white;
  }
}

.remove-box {
  display: block;
  border: 1px solid $light-semi-gray;
  height: max-content;
  width: max-content;
  padding: 4px 18px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px gainsboro;
  color: $red;
  cursor: pointer;
  background-color: $white;
  .fa-trash-o {
    padding-right: 5px;
    color: #afafaf;
    font-size: 16px;
  }
}

table.detailtable td {
  vertical-align: baseline;
}

.avs-title {
  padding-bottom: 10px;
}

.e-row.expired {
  td {
    background-color: $salmon !important;
    &.expiration-txt {
      color: $red;
    }
  }
}

.e-grid.e-gridhover
  tr[role='row'].expired:not(.e-editedrow):hover
  .e-rowcell.expiration-txt:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  color: $red;
}

.expired-box {
  display: none;
  height: max-content;
  width: 109.52px;
  padding: 4px 18px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px gainsboro;
  color: $red;
  background-color: $salmon;
  border: 1px solid $salmon2;
  text-align: center;
}

.e-row.expired + .e-detailrow .expired-box {
  display: block;
}

.group-action-save {
  width: 30%;
  margin: 0;
}

.group-accordion {
  display: inline;
  padding: 0;
  margin: 0;
  .card-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 18px;
    h6 {
      color: #0c697e;
      text-decoration: underline;
    }
  }
}

.user-order-id {
  color: #0c697e;
  font-weight: bold;
}

.user-transactions-title {
  font-size: 16px !important;
  margin-right: 5px;
  color: black !important;
  font-weight: bold;
}
