.customer-call {
  display: flex;
  justify-content: center;
  color: $success-color;
  cursor: pointer;
  &:hover {
    color: $gray-60;
  }
}

.cursor-copy {
  cursor: copy;
}

.order-details-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .priority-select {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 18px;
    vertical-align: middle;
    .control-pane {
      padding: 0.25rem 0.75rem;
    }
  }
  .order-status-dropdown {
    margin-right: 17px;
  }
  .order-actions-icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .payment-icon {
      max-width: 72px;
      max-height: 32px;
      margin-right: 12px;
      margin-bottom: 3px;
    }
    .channel-icon {
      max-width: 32px;
      margin-right: 12px;
      margin-bottom: 8px;
    }
    .printed-status-btn {
      padding: 5px;
      margin-top: 5px;
      margin-right: 14px;
      font-size: 12px;
    }
    .order-action-driver-wrapper {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      .order-action-driver-name {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
      .order-action-driver-phone {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
        color: #0c697e;
      }
    }
  }
}

.dropdown-item {
  background-color: $secondary-color;
  color: $white;
  border-bottom: 1px solid $white;
  width: 250px;
  &:hover {
    background-color: $secondary-color !important;
    cursor: pointer;
  }
}

.copy-order {
  color: black;
  cursor: pointer;
  font-size: 15px;
}

@media (max-width: 500px) {
  .order-details-actions {
    flex-direction: column;
  }
}

.priority-flag-details {
  font-size: 22px !important;
}

.order-details-wrapper {
  .order-details-header {
    font-size: 18px;
  }
  .order-details-container {
    display: flex;
    margin-bottom: 15px;
    &.order-details-mobile {
      margin-top: 15px;
    }
    .order-ops-title {
      color: #0c697e;
      margin-right: 3px;
    }
    .fa-star {
      color: #daa520;
      font-size: 22px;
      margin-left: 5px;
    }
    .order-flag {
      width: 45px;
      align-self: baseline;
    }
    .order-details-title {
      cursor: context-menu;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .recent-notification {
    .fa-headphones,
    .fa-phone,
    .fa-envelope {
      font-size: 22px;
      margin-right: 5px;
      align-self: center;
    }
    .customer-call-title {
      font-size: 18px;
    }
  }
}
