.quick-actions-wrapper {
  img {
    width: 25px;
    cursor: pointer;
    margin-right: 6px;

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }
}

.quick-action-see-more {
  color: $success-color;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 8px;
}

.braille-icon {
  width: 30px;
  margin: 5px 0;
}

.action-btns-wrapper {
  display: flex;
  .print-app-download {
    padding: 7px 10px;
    background-color: $success-color;
    margin-right: 18px;
    border-radius: 5px;
    align-self: flex-start;
  }
}
