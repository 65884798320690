.product-tabs-wrapper {
  padding-top: 20px;
  .btn-success {
    margin-bottom: 15px;
  }
  .payment-link {
    font-size: 15px;
  }
  .generated-pay-link {
    text-decoration: underline;
    color: #0c697e;
    cursor: pointer;
  }
  .generated-date {
    font-size: 15px;
  }
}
