.driver-modal-table {
  border-collapse: unset;
}

.picked-orders {
  font-weight: bold;
  font-size: 15px;
  color: #0c697e;
  span {
    font-size: 15px;
    color: black;
  }
}
