.ops-selector {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 0 8px !important;
  .ops-img {
    max-width: 100%;
    margin-bottom: 8px;
    border-radius: 50%;
  }
}
.authentication-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  margin: 0 auto;
  width: 60%;
  max-width: unset;
  .card {
    box-shadow: none;
    background-color: $light;
  }
}
.login-title {
  text-align: left;
  color: $primary-color;
  margin-right: 20px;
  // &::before{
  //   height: 15px;
  //   background-color: $primary-color;
  //   content: "";
  //   padding: 0 2px;
  //   margin-left: -20px;
  //   margin-right: 16px;
  // }
}
.login-desc {
  text-align: left;
  color: $light-blue;
}
.login-label {
  padding-bottom: 0;
  margin-bottom: -10px !important;
  margin-left: 10px;
  display: block;
  background-color: $white;
  border-radius: 7px;
  padding: 0 10px;
  width: max-content;
  overflow: overlay;
  color: $primary-color !important;
}
.login-input {
  padding: 0.675rem 0.75rem !important;
}
.login-btn {
  padding: 0.675rem 1.75rem !important;
}
.password-form {
  position: relative;
  .showhide-icon {
    width: 25px;
    bottom: 10px;
    right: 10px;
    position: absolute;
  }
}
.login-slider {
  margin-top: -50px;
  margin-bottom: -70px;
}
.login-slide-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
}

.ms-login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  .ms-icon {
    width: 20px;
    margin-right: 10px;
  }
}
