/**=====================
    24. According CSS start
==========================**/
.default-according {
  .card {
    margin-bottom: 0;
    box-shadow: none !important;
    & + .card {
      margin-top: $according-card-top-margin;
    }
    .btn-link {
      font-weight: $according-btn-weight;
      color: $theme-body-font-color;
      text-decoration: none;
    }
    .btn-link {
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
    .card-header {
      padding: $according-card-header-padding;
      border: 1px solid $card-border-color;
      &:first-child {
        border-radius: 5px !important;
      }
      i {
        position: absolute;
        left: 18px;
        font-size: 20px;
        top: 20px;
      }
      h5,
      h4 {
        margin-top: 2px;
      }
    }
    .card-body {
      border: 1px solid $card-border-color;
      border-top: none !important;
    }
  }
}
.default-according.style-1 {
  button {
    width: 100%;
    text-align: left;
    &:before {
      right: 20px;
      position: absolute;
      transition: 0.4s;
    }
    &[aria-expanded='true'] {
      &:before {
        content: $according-open-icon;
        font-family: $according-card-header-icon;
      }
    }
    &[aria-expanded='false'] {
      &:before {
        content: $according-close-icon;
        font-family: $according-card-header-icon;
      }
    }
  }
}
/**=====================
     24. According CSS Ends
==========================**/
