.product-page {
  > .card:first-child > .card-body {
    display: flex;
    justify-content: space-between;
    .btn-secondary .fa-refresh {
      margin-right: 5px;
    }
  }
  > .card:nth-child(2) > .card-body {
    padding: 20px 0;
  }
  .tab-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .tabs-content-wrapper {
    padding: 0;
    margin: 12px;
  }
  .new-product-group-btn {
    margin-bottom: 40px;
  }
  .product-group-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    & > p {
      margin: 0;
      font-size: 18px;
      margin-right: 20px;
    }
    .product-group-bg {
      padding: 10px 20px;
      background-color: rgb(6, 53, 81);
      border-radius: 5px;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 18px;
        color: white;
        font-weight: bold;
        margin: 0;
      }
      .product-group-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        .product-group-action {
          font-size: 18px;
          border-radius: 5px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          padding: 0;
        }
      }
    }
  }
  .product-excluded-table {
    width: 100%;
    border: 1px solid #e3e3e3;
    // margin-bottom: 20px;
    > tr {
      border-bottom: 1px solid #e3e3e3;
      height: 42px;
      color: #0000008a;
      font-size: 12px;
      font-weight: 500;
      line-height: 30px;
      font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif, -apple-system,
        BlinkMacSystemFont;
    }
    td {
      text-align: center;
      padding: 0 0.6em;
      .form-group {
        margin-bottom: 1rem;
        margin-top: 1rem;
        .checkbox_animated {
          margin: 0;
          &:after {
            width: 1.2rem;
            height: 1.2rem;
            border: 2px solid #bdbdbd;
            border-radius: 3px;
          }
          &:checked:after {
            background-color: #0c697e;
            border: 2px solid #0c697e;
          }
          &:before {
            border: 2px solid #fafafa;
            border-top-style: none;
            border-right-style: none;
          }
        }
      }
    }
  }
  .product-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    margin-left: 15%;
    & > p {
      margin: 0;
      font-size: 18px;
      margin-right: 20px;
    }
    .product-item-bg {
      padding: 10px 20px;
      border-radius: 5px;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .item-product-img {
        width: 80px;
        height: 80px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      a {
        font-size: 16px;
        margin: 0;
        color: #0c697e;
        width: 150px;
        text-align: center;
      }
      p {
        font-size: 16px;
        margin: 0;
        width: 80px;
        text-align: center;
      }
      .product-group-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        .product-group-action {
          font-size: 18px;
          border-radius: 5px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          padding: 0;
        }
      }
    }
  }
  .col-end-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .attributes-card {
    border: 1px solid #f1f2f3;
    border-top: 2px solid #53a6d6;
    margin-top: 50px;
    padding: 0px 20px;
    .add-attr-col {
      margin-top: 20px;
      margin-left: -5px;
    }
    .attributes-container {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 10px 30px 10px;
      .attr-chip {
        margin: 4px;
      }
      li {
        list-style: none;
        &:nth-child(2n + 1) .attr-chip {
          background-color: #53a6d6;
          color: white;
        }
        &:nth-child(2n) .attr-chip {
          background-color: #b9e5ff;
        }
      }
    }
  }
}

.published-caution {
  color: $white;
  font-size: 14px;
  background-color: #f39c12;
  padding: 7px;
  border-radius: 5px;
  margin-left: 0;
  margin-bottom: 20px;
  .fa {
    margin-right: 10px;
    margin-left: 5px;
  }

  .submission-wrapper {
    display: flex;
  }
  .warrning-icon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid white;
  }

  .submission-data {
    padding-left: 10px;
  }

  .submission-header {
    font-weight: 800;
  }
  .submission-body {
    display: flex;
    flex-direction: column;
  }
}

.categories-field-box {
  margin-bottom: 15px;
}

.type-warning-message {
  font-size: 13px;
  font-weight: normal;
  color: red;
}

.no-arrows-input::-webkit-outer-spin-button,
.no-arrows-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows-input {
  -moz-appearance: textfield;
}

.product-actions-wrapper {
  .sync-product-btn {
    margin-left: 10px;
  }
}

.exceeded-dim-message {
  color: red;
  margin-bottom: 2px;
  margin-top: 15px;
}
.exceeded-img-name {
  margin-bottom: 0;
  .bult-icon {
    margin-right: 4px;
    font-size: 10px;
  }
}

.availability-row-checkbox {
  margin: 10px 0 !important;
  text-align: left !important;
}

.availability-row-button {
  align-self: end !important;
}
