.filter-select {
  &.no-margin {
    margin-bottom: 0;
    .control-pane {
      margin-bottom: 0;
    }
  }
  &.disabled-select {
    .control-pane {
      background-color: #efefef;
    }
  }
  .control-pane {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $light-semi-gray;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  input.e-dropdownlist {
    padding: 0 !important;
    font-size: 14px !important;
  }
  span.e-keyboard {
    margin: 0;
    border: 0 !important;
  }
}
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
  color: $primary-color !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: $primary-color;
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon {
  margin: 0;
  &::before {
    content: '\e5cf';
    font-family: Material Icons;
    font-size: 20px;
    font-weight: bold;
    color: $brown-color;
  }
}

.e-multiselect.e-input-group .e-ddl-icon::before {
  content: '\e5cf';
  font-family: Material Icons;
  font-size: 20px;
  font-weight: bold;
  color: $brown-color;
}

.searchHeader-select {
  .control-pane {
    position: relative;
    box-sizing: border-box;
    right: 20%;
    width: 18%;
  }
  span.e-keyboard {
    margin: 0;
    border: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .searchHeader-select {
    .control-pane {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
