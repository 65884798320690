.card {
  margin-bottom: 15px;
}
.card-header.card-header-border h5 {
  padding-top: 0 !important;
}

.marker-style-center {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
}

.map-modal-content-wrapper {
  height: 100%;
  position: relative;
}

.map-auto-complete {
  height: 40px;
  position: absolute;
  padding-left: 10px;
  top: 60px;
  right: 11px;
  width: 95%;
  background-color: $white;
  color: black;
  border: 0.1px solid $primary;
  border-radius: 5px;
  outline-color: $primary;

  &:focus {
    border: 0.1px solid $black !important;
    outline-color: $primary;
  }
}

.pac-container {
  z-index: 2000;
}

.accordion__item:focus {
  outline-color: $white !important;
}
.accordion__heading:focus {
  outline-color: $white !important;
}
// .accordion__button:focus {
//   outline-color: $primary !important;
// }

.accordion__button:focus {
  outline-color: $white !important;
}

.access-denied-img {
  width: 35%;
  border-radius: 10px;
}

@media (max-width: 500px) {
  .access-denied-img {
    width: 80%;
  }
}

.error-message {
  font-size: 14px;
  color: $danger-color;
}

.MuiFormControlLabel-root {
  margin: 0 !important;
}

.like-comment {
  margin-top: -10px !important;
}

@media (max-width: 500px) {
  .rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: column;
    .rdrDefinedRangesWrapper {
      width: 100%;
    }
  }
  .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
    padding-bottom: 80px !important;
  }
}

.sidebar-submenu {
  margin-left: 20px !important;
}
